import {startRegistration, startAuthentication, browserSupportsWebAuthn, browserSupportsWebAuthnAutofill} from '@simplewebauthn/browser';

window.webauthnSupported = function () {
    return browserSupportsWebAuthn();
};

window.webauthnAutofillSupported = async function () {
    return await browserSupportsWebAuthnAutofill();
};

window.webauthnRegisterKey = async function (options) {
    return await startRegistration(options);
};

window.webauthnAuthenticateKey = async function (options, autofill = false) {
    return await startAuthentication(options, autofill);
};
